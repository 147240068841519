export const MODELS_PATHS = {
  "Fish Coal": "FishCoal.glb",
  K3: "K3MidlandsGoodShed.glb",
  R7: "R7Handyside.glb",
  "Q1 Handyside": "Q1Handyside.glb",
  "Western Transit": "WesternTransitShed.glb",
  "Two Pancras Square": "2PancrasSquare.glb",
  "Three Pancras Square": "3PancrasSquare.glb",
  "Four Pancras Square": "4PancrasSquare.glb",
  "One Pancras Square": "1PancrasSquare.glb",
  "11 - 21 Canal Reach": "11-21CanalReach.glb",
  "Coal Drops Yard": "CoalDropsYard.glb",
  "10 Cubitt Square": "10CubittSquare.glb",
  "S1-Handyside": "S1Handyside.glb",
  "S2 Handyside": "S2Handyside.glb",
  "Television Centre": "TelevisionCentre.glb",
  "Walt Disney": "WaltDisney.glb",
  "Bloom Clenkerwell": "BloomClerkenwell.glb",
  "Kings Place": "KingsPlace.glb",
  "Lloyds Of London": "LloydsOfLondonSite.glb",
  "Lloyds Of London Surroundings": "LloydsOfLondonSurroundings.glb",
  "10 Portman Square": "10PortmanSquare.glb",
  "Marble Arch House": "MarbleArchHouse.glb",
  "York House": "YorkHouse.glb",
  "201 Bishopsgate": "201BishopsgateSite.glb",
  "2 London Wall": "2LondonWallSite.glb",
  "2 London Wall Surroundings": "2LondonWallSurroundings.glb",
  "99 Bishopsgate": "99Bishopsgate.glb",
  "Dashwood": "DashwoodSite.glb",
  "Dashwood Surroundings": "DashwoodSurroundings.glb",
  "22 Bishopsgate": "22BishopsgateSite.glb",
  "22 Bishopsgate Surroundings": "22BishopsgateSurroundings.glb",
  "30 St Mary's Axe": "30StMarysAxe.glb",
  "3 Sheldon Square": "3SheldonSquare.glb",
  "4 Kingdom Street": "4KingdomStreet.glb",
  Ropemaker: "Ropemaker.glb",
  "2 Kingdom Street": "2KingdomStreet.glb",
  "6 More London": "6MoreLondonSite.glb",
  "6 More London Surroundings": "6MoreLondonSurroundings.glb",
  "The Crick": "TheCrick.glb",
  "199 Bishopsgate": "199BishopsgateSite.glb",
  "110 Bishopsgate": "110Bishopsgate.glb",
  "1 Appold Street": "1AppoldStreetSite.glb",
  "Exchange House": "ExchangeHouseSite.glb",
  Broadwalk: "BroadWalkSite.glb",
  "Exchange Square": "ExchangeSquareSite.glb",
  "Broadgate Tower": "BroadgateTowerSite.glb",
  "1 Finsbury Avenue": "1FinsburyAvenueSite.glb",
  "The Shard": "TheShardSite.glb",
  "The Shard Surroundings": "TheShardSurroundings.glb",
  "135 Bishopsgate": "135BishopsgateSite.glb",
  "155 Bishopsgate": "155BishopsgateSite.glb",
  "100 Liverpool Street": "100LiverpoolStreetSite.glb",
  "One New Change": "OneNewChangeSite.glb",
  "One New Change Surroundings": "OneNewChangeSurroundings.glb",
  "30 North Colonnade": "30NorthColonnade.glb",
  "1 London Bridge": "1LondonBridgeSite.glb",
  "1 London Bridge Surroundings": "1LondonBridgeSurroundings.glb",
  "Cottons Centre": "CottonsCentreSite.glb",
  "Cottons Centre Surroundings": "CottonsCentreSurroundings.glb",
  "2 More London": "2MoreLondonSite.glb",
  "2 More London Surroundings": "2MoreLondonSurroundings.glb",
  "Hay's Galleria": "HaysGalleriaSite.glb",
  "Hay's Galleria Surroundings": "HaysGalleriaSurroundings.glb",
  Bluefin: "Bluefin.glb",
  "Wellcome Trust": "WellcomeTrustSite.glb",
  "Wellcome Trust Surroundings": "WellcomeTrustSurroundings.glb",
  "Aldgate Tower": "AldgateTower.glb",
  "Cannon Place": "CannonPlace.glb",
  "1 Sheldon Square": "1SheldonSquare.glb",
  CityPoint: "CityPoint.glb",
  "Islington Square": "IslingtonSquare.glb",
  "20 Fenchurch Street": "20FenchurchStreetSite.glb",
  "20 Fenchurch Street Surroundings": "20FenchurchStreetSurroundings.glb",
  "Lloyds Of London Metals": "LloydsOfLondonMetalsSite.glb",
  "Lloyds Of London Metals Surroundings": "LloydsOfLondonMetalsSurroundings.glb",
  "183 Euston Road": "183EustonRoad.glb",
  "Paddington Square": "PaddingtonSquare.glb",
  "One Wood Crescent": "OneWoodCrescent.glb",
  "40 Leadenhall": "40LeadenhallSite.glb",
  "40 Leadenhall Surroundings": "40LeadenhallSurroundings.glb",
  "61 Southwark Street": "61SouthwarkStreet.glb",
  "2 Redman Place": "2RedmanPlace.glb",
  "Park House": "ParkHouse.glb",
  "16 Palace Street": "16PalaceStreetSite.glb",
  "16 Palace Street Surroundings": "16PalaceStreetSurroundings.glb",
  "The Zig Zag Building": "TheZigZagBuildingSite.glb",
  "The Zig Zag Building Surroundings": "TheZigZagBuildingSurroundings.glb",
  "Lucent": "Lucent.glb",
  "123 Victoria Street": "123VictoriaStreetSite.glb",
  "123 Victoria Street": "123VictoriaStreetSurroundings.glb",
  "The Forge": "TheForge.glb",
  "24 Southwark Bridge Road": "24SouthwarkBridgeRoadSite.glb",
  "24 Southwark Bridge Road Surroundings": "24SouthwarkBridgeRoadSurroundings.glb",
  "62 Buckingham Gate": "62BuckinghamGateSite.glb",
  "62 Buckingham Gate Surroundings": "62BuckinghamGateSurroundings.glb",
  "80-100 Victoria Street": "80-100VictoriaStreetSite.glb",
  "80-100 Victoria Street Surroundings": "80-100VictoriaStreetSurroundings.glb",
  "One Station Hill": "OneStationHill.glb",
  "80 Fenchurch Street": "80FenchurchStreet.glb",
  "1 Leadenhall": "1Leadenhall.glb",
  "66 Victoria Street": "66VictoriaStreet.glb",
  "Bloomberg": "Bloomberg.glb",
  "Shard Place": "ShardPlace.glb",
  "4 More London": "4MoreLondon.glb",
  "Visa Reading": "VisaReading.glb",
  "Blossom Yard & Studios": "BlossomYard&Studios.glb",
};
